import React from 'react';

import { HeroBackground, H2 } from 'playful-ui';

import { App, Helmet } from 'modules/app';
import { MainNavigation } from 'modules/navigation';
import { PersonalDataProtection } from 'modules/legal';

export default class PersonalDataProtectionPage extends React.Component {
  render() {
    return (
      <App>
        <Helmet
          route={PersonalDataProtection.route}
          title={PersonalDataProtection.title.props.id}
        />
        <MainNavigation />
        <HeroBackground textual>
          <H2>{PersonalDataProtection.title}</H2>
        </HeroBackground>
        <PersonalDataProtection />
      </App>
    );
  }
}
